import React from 'react'

function WhatsAppIconSvg() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="37" height="37" rx="18.5" fill="url(#paint0_linear_95_1332)"/>
    <path d="M7.27818 29.263L8.86446 23.5027C7.88375 21.812 7.36929 19.8972 7.37465 17.9398C7.37465 11.8062 12.3907 6.81934 18.5482 6.81934C21.5386 6.81934 24.3467 7.97672 26.4528 10.0781C28.5642 12.1796 29.7272 14.9744 29.7218 17.9452C29.7218 24.0787 24.7057 29.0656 18.5429 29.0656H18.5375C16.6672 29.0656 14.8291 28.5963 13.1946 27.7109L7.27818 29.263ZM13.4786 25.7002L13.8162 25.9028C15.2417 26.7455 16.8762 27.1882 18.5429 27.1936H18.5482C23.6661 27.1936 27.8354 23.0494 27.8354 17.9505C27.8354 15.481 26.8708 13.1609 25.1184 11.4115C23.366 9.66213 21.0295 8.70208 18.5482 8.70208C13.4303 8.69675 9.26102 12.8409 9.26102 17.9398C9.26102 19.6839 9.7487 21.3853 10.6812 22.8574L10.9009 23.2094L9.96306 26.6175L13.4786 25.7002Z" fill="white"/>
    <path d="M7.6694 28.8737L9.20209 23.3108C8.25354 21.684 7.75515 19.8333 7.75515 17.9452C7.76051 12.0249 12.5997 7.20874 18.5482 7.20874C21.4367 7.20874 24.1431 8.32879 26.1795 10.3555C28.2159 12.3823 29.336 15.0811 29.336 17.9505C29.336 23.8708 24.4914 28.687 18.5482 28.687H18.5429C16.7369 28.687 14.963 28.2337 13.3875 27.3803L7.6694 28.8737Z" fill="url(#paint1_linear_95_1332)"/>
    <path d="M7.27818 29.263L8.86446 23.5027C7.88375 21.812 7.36929 19.8972 7.37465 17.9398C7.37465 11.8062 12.3907 6.81934 18.5482 6.81934C21.5386 6.81934 24.3467 7.97672 26.4528 10.0781C28.5642 12.1796 29.7272 14.9744 29.7218 17.9452C29.7218 24.0787 24.7057 29.0656 18.5429 29.0656H18.5375C16.6672 29.0656 14.8291 28.5963 13.1946 27.7109L7.27818 29.263ZM13.4786 25.7002L13.8162 25.9028C15.2417 26.7455 16.8762 27.1882 18.5429 27.1936H18.5482C23.6661 27.1936 27.8354 23.0494 27.8354 17.9505C27.8354 15.481 26.8708 13.1609 25.1184 11.4115C23.366 9.66213 21.0295 8.70208 18.5482 8.70208C13.4303 8.69675 9.26102 12.8409 9.26102 17.9398C9.26102 19.6839 9.7487 21.3853 10.6812 22.8574L10.9009 23.2094L9.96306 26.6175L13.4786 25.7002Z" fill="url(#paint2_linear_95_1332)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7561 13.2891C15.5471 12.825 15.3274 12.8144 15.1291 12.809C14.9683 12.8037 14.7807 12.8037 14.5932 12.8037C14.4056 12.8037 14.1055 12.873 13.8483 13.1504C13.591 13.4277 12.8729 14.0998 12.8729 15.4705C12.8729 16.8359 13.8751 18.1586 14.0144 18.3453C14.1537 18.532 15.949 21.4281 18.7839 22.5428C21.1419 23.4708 21.6242 23.2842 22.1333 23.2362C22.6424 23.1882 23.7839 22.5641 24.0197 21.9134C24.2501 21.2627 24.2501 20.7081 24.1805 20.5907C24.1108 20.4734 23.9232 20.404 23.6446 20.2654C23.3659 20.1267 21.994 19.4547 21.7368 19.3587C21.4795 19.268 21.292 19.22 21.1098 19.4973C20.9222 19.7747 20.3863 20.3987 20.2255 20.5854C20.0647 20.7721 19.8986 20.7934 19.6199 20.6547C19.3413 20.516 18.441 20.2227 17.3745 19.2733C16.5439 18.5373 15.9812 17.6253 15.8204 17.3479C15.6596 17.0706 15.8043 16.9212 15.9436 16.7825C16.0669 16.6599 16.2223 16.4572 16.3617 16.2972C16.501 16.1372 16.5492 16.0199 16.6403 15.8332C16.7314 15.6465 16.6886 15.4865 16.6189 15.3478C16.5492 15.2145 16.0026 13.8384 15.7561 13.2891Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_95_1332" x1="18.499" y1="36.9982" x2="18.499" y2="-0.00129453" gradientUnits="userSpaceOnUse">
    <stop stop-color="#20B038"/>
    <stop offset="1" stop-color="#60D66A"/>
    </linearGradient>
    <linearGradient id="paint1_linear_95_1332" x1="18.5021" y1="28.8726" x2="18.5021" y2="7.20798" gradientUnits="userSpaceOnUse">
    <stop stop-color="#20B038"/>
    <stop offset="1" stop-color="#60D66A"/>
    </linearGradient>
    <linearGradient id="paint2_linear_95_1332" x1="18.5022" y1="29.2609" x2="18.5022" y2="6.81934" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F9F9F9"/>
    <stop offset="1" stop-color="white"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default WhatsAppIconSvg