const VerifiedSvg=()=>{
    return(
        <svg width="158" height="158" viewBox="0 0 158 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M79 158C122.631 158 158 122.631 158 79C158 35.3695 122.631 0 79 0C35.3695 0 0 35.3695 0 79C0 122.631 35.3695 158 79 158Z" fill="url(#paint0_linear_3_969)"/>
<path d="M74.5555 44.736C76.9483 44.736 78.888 42.7962 78.888 40.4035C78.888 38.0107 76.9483 36.071 74.5555 36.071C72.1628 36.071 70.2231 38.0107 70.2231 40.4035C70.2231 42.7962 72.1628 44.736 74.5555 44.736Z" fill="#B5B2E5"/>
<path opacity="0.3" d="M31.9753 49.8218C35.2222 49.8218 37.8544 47.1897 37.8544 43.9428C37.8544 40.6958 35.2222 38.0637 31.9753 38.0637C28.7284 38.0637 26.0963 40.6958 26.0963 43.9428C26.0963 47.1897 28.7284 49.8218 31.9753 49.8218Z" fill="#968DF4"/>
<path opacity="0.3" d="M133.667 69.2469C136.241 69.2469 138.328 67.1603 138.328 64.5863C138.328 62.0124 136.241 59.9258 133.667 59.9258C131.093 59.9258 129.007 62.0124 129.007 64.5863C129.007 67.1603 131.093 69.2469 133.667 69.2469Z" fill="#8294EC"/>
<path d="M110.154 42.2582L22.0673 59.1731C17.8817 59.9769 15.1402 64.0216 15.9439 68.2072L25.3888 117.392C26.1925 121.578 30.2372 124.32 34.4229 123.516L122.509 106.601C126.695 105.797 129.437 101.752 128.633 97.5668L119.188 48.3816C118.384 44.196 114.339 41.4544 110.154 42.2582Z" fill="#C1A1FF"/>
<path d="M112.422 41.8223L24.3354 58.7372C20.1497 59.541 17.4082 63.5857 18.2119 67.7713L27.6568 116.957C28.4606 121.142 32.5053 123.884 36.6909 123.08L124.777 106.165C128.963 105.361 131.705 101.317 130.901 97.1309L121.456 47.9457C120.652 43.7601 116.607 41.0185 112.422 41.8223Z" fill="white"/>
<path d="M112.985 41.8442L23.1801 59.0856C19.4673 59.799 17.1136 63.7722 17.9155 67.938L19.3319 75.3168L122.577 55.4926L121.156 48.1295C120.359 43.9272 116.693 41.1308 112.985 41.8442Z" fill="#7445CC"/>
<path d="M47.2224 87.6966L28.7563 91.2426C27.7875 91.4286 27.153 92.3647 27.339 93.3335L31.4163 114.566C31.6023 115.535 32.5384 116.17 33.5072 115.983L51.9734 112.437C52.9421 112.251 53.5766 111.315 53.3906 110.347L49.3133 89.1138C49.1273 88.1451 48.1912 87.5106 47.2224 87.6966Z" fill="#C1A1FF"/>
<path d="M40.8001 102.453C43.0652 102.018 44.5449 99.8088 44.1051 97.5183C43.6652 95.2278 41.4724 93.7236 39.2073 94.1585C36.9422 94.5935 35.4626 96.8029 35.9024 99.0934C36.3423 101.384 38.535 102.888 40.8001 102.453Z" fill="white"/>
<path d="M44.4573 102.495C44.2246 102.434 43.9807 102.429 43.7455 102.479C43.5102 102.53 43.2903 102.635 43.1034 102.787C42.5027 103.259 41.798 103.58 41.048 103.724C40.298 103.868 39.5244 103.831 38.7917 103.615C38.5613 103.545 38.3177 103.529 38.0801 103.569C37.8425 103.609 37.6174 103.703 37.4222 103.844C36.4529 104.582 35.6582 105.524 35.0947 106.605C34.5313 107.685 34.213 108.876 34.1625 110.093C34.1512 110.327 34.1926 110.56 34.2837 110.776C34.3748 110.991 34.5132 111.184 34.6887 111.339C34.8642 111.494 35.0723 111.607 35.2975 111.671C35.5228 111.735 35.7595 111.747 35.9902 111.707L48.696 109.265C48.9255 109.217 49.1413 109.118 49.3275 108.976C49.5136 108.834 49.6654 108.651 49.7714 108.442C49.8774 108.233 49.935 108.003 49.94 107.768C49.9449 107.534 49.8971 107.302 49.8 107.088C49.3017 105.977 48.5646 104.989 47.6412 104.196C46.7177 103.402 45.6306 102.821 44.4573 102.495Z" fill="white"/>
<path d="M62.1558 83.4664L107.441 74.587" stroke="#7545CE" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M65.7076 98.5614L105.665 90.5699" stroke="#7545CE" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M111.436 62.1558C121.98 62.1558 130.527 53.6086 130.527 43.0651C130.527 32.5216 121.98 23.9744 111.436 23.9744C100.893 23.9744 92.3458 32.5216 92.3458 43.0651C92.3458 53.6086 100.893 62.1558 111.436 62.1558Z" fill="#E36722"/>
<path d="M114.544 62.1558C124.843 62.1558 133.191 53.6086 133.191 43.0651C133.191 32.5216 124.843 23.9744 114.544 23.9744C104.246 23.9744 95.8975 32.5216 95.8975 43.0651C95.8975 53.6086 104.246 62.1558 114.544 62.1558Z" fill="#F38548"/>
<path d="M114.573 60.3799C111.06 60.3855 107.623 59.3747 104.699 57.4753C101.774 55.5759 99.4936 52.8733 98.1451 49.7096C96.7967 46.5459 96.4412 43.0633 97.1236 39.7025C97.806 36.3417 99.4956 33.2539 101.979 30.8297C104.462 28.4056 107.627 26.7541 111.073 26.0844C114.519 25.4146 118.091 25.7566 121.338 27.0672C124.584 28.3777 127.359 30.5979 129.311 33.4467C131.263 36.2954 132.304 39.6448 132.303 43.0708C132.298 47.6561 130.43 52.0527 127.107 55.2972C123.783 58.5416 119.276 60.3694 114.573 60.3799ZM114.573 26.3251C111.175 26.3195 107.852 27.2968 105.024 29.1334C102.195 30.97 99.9895 33.5834 98.685 36.6427C97.3806 39.7021 97.0363 43.07 97.6957 46.3202C98.3551 49.5704 99.9886 52.5569 102.389 54.9016C104.79 57.2464 107.85 58.8441 111.183 59.4925C114.515 60.141 117.97 59.811 121.11 58.5444C124.25 57.2778 126.934 55.1315 128.823 52.3771C130.711 49.6227 131.719 46.384 131.719 43.0708C131.713 38.6363 129.906 34.3848 126.692 31.2465C123.479 28.1082 119.122 26.3387 114.573 26.3251Z" fill="#E36722"/>
<path d="M111.978 51.5268C111.673 51.5268 111.375 51.4377 111.121 51.2706C110.866 51.1034 110.666 50.8655 110.545 50.5861C110.424 50.3067 110.387 49.998 110.439 49.6979C110.491 49.3978 110.629 49.1195 110.838 48.8971L122.085 36.8786C122.37 36.5822 122.76 36.4097 123.171 36.3987C123.581 36.3877 123.98 36.539 124.28 36.8199C124.58 37.1007 124.757 37.4885 124.773 37.8991C124.789 38.3097 124.643 38.7101 124.366 39.0136L113.118 51.0321C112.972 51.1882 112.796 51.3127 112.599 51.3978C112.403 51.4828 112.192 51.5268 111.978 51.5268Z" fill="white"/>
<path d="M112.035 51.5267C111.807 51.5263 111.581 51.4757 111.374 51.3785C111.167 51.2813 110.984 51.1399 110.838 50.9643L105.969 45.1581C105.729 44.8384 105.622 44.4392 105.667 44.0424C105.713 43.6456 105.909 43.2815 106.215 43.0248C106.521 42.7681 106.914 42.6384 107.313 42.6623C107.711 42.6862 108.086 42.8619 108.359 43.1533L113.233 48.9595C113.424 49.1873 113.547 49.4651 113.586 49.7601C113.625 50.0552 113.579 50.3552 113.453 50.6249C113.327 50.8946 113.127 51.1228 112.876 51.2825C112.624 51.4423 112.333 51.527 112.035 51.5267Z" fill="white"/>
<path opacity="0.3" d="M65.2603 138.296C68.5072 138.296 71.1394 135.663 71.1394 132.417C71.1394 129.17 68.5072 126.537 65.2603 126.537C62.0134 126.537 59.3813 129.17 59.3813 132.417C59.3813 135.663 62.0134 138.296 65.2603 138.296Z" fill="#968DF4"/>
<defs>
<linearGradient id="paint0_linear_3_969" x1="79" y1="0" x2="79" y2="158" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6C1FF"/>
<stop offset="1" stop-color="#E6DAFF"/>
</linearGradient>
</defs>
</svg>

    )
}
export default VerifiedSvg;