import React from 'react'

function DeleteIconSvg() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3749 3.5H15.6624C15.4594 2.51249 14.922 1.62518 14.141 0.987628C13.36 0.350079 12.3831 0.00127271 11.3749 0L9.62494 0C8.61676 0.00127271 7.63984 0.350079 6.85884 0.987628C6.07785 1.62518 5.54052 2.51249 5.33744 3.5H2.62494C2.39287 3.5 2.17031 3.59219 2.00622 3.75628C1.84213 3.92038 1.74994 4.14294 1.74994 4.375C1.74994 4.60706 1.84213 4.82962 2.00622 4.99372C2.17031 5.15781 2.39287 5.25 2.62494 5.25H3.49994V16.625C3.50133 17.7849 3.96271 18.8969 4.78288 19.7171C5.60305 20.5372 6.71504 20.9986 7.87494 21H13.1249C14.2848 20.9986 15.3968 20.5372 16.217 19.7171C17.0372 18.8969 17.4985 17.7849 17.4999 16.625V5.25H18.3749C18.607 5.25 18.8296 5.15781 18.9937 4.99372C19.1578 4.82962 19.2499 4.60706 19.2499 4.375C19.2499 4.14294 19.1578 3.92038 18.9937 3.75628C18.8296 3.59219 18.607 3.5 18.3749 3.5ZM9.62494 1.75H11.3749C11.9177 1.75066 12.4469 1.9192 12.8901 2.23251C13.3333 2.54582 13.6687 2.98856 13.8503 3.5H7.14956C7.3312 2.98856 7.6666 2.54582 8.10978 2.23251C8.55295 1.9192 9.0822 1.75066 9.62494 1.75ZM15.7499 16.625C15.7499 17.3212 15.4734 17.9889 14.9811 18.4812C14.4888 18.9734 13.8211 19.25 13.1249 19.25H7.87494C7.17875 19.25 6.51107 18.9734 6.01878 18.4812C5.5265 17.9889 5.24994 17.3212 5.24994 16.625V5.25H15.7499V16.625Z" fill="#374957"/>
<path d="M8.75 15.7495C8.98206 15.7495 9.20462 15.6573 9.36872 15.4932C9.53281 15.3291 9.625 15.1066 9.625 14.8745V9.62451C9.625 9.39245 9.53281 9.16989 9.36872 9.00579C9.20462 8.8417 8.98206 8.74951 8.75 8.74951C8.51793 8.74951 8.29538 8.8417 8.13128 9.00579C7.96719 9.16989 7.875 9.39245 7.875 9.62451V14.8745C7.875 15.1066 7.96719 15.3291 8.13128 15.4932C8.29538 15.6573 8.51793 15.7495 8.75 15.7495Z" fill="#374957"/>
<path d="M12.2499 15.7495C12.4819 15.7495 12.7045 15.6573 12.8686 15.4932C13.0327 15.3291 13.1249 15.1066 13.1249 14.8745V9.62451C13.1249 9.39245 13.0327 9.16989 12.8686 9.00579C12.7045 8.8417 12.4819 8.74951 12.2499 8.74951C12.0178 8.74951 11.7953 8.8417 11.6312 9.00579C11.4671 9.16989 11.3749 9.39245 11.3749 9.62451V14.8745C11.3749 15.1066 11.4671 15.3291 11.6312 15.4932C11.7953 15.6573 12.0178 15.7495 12.2499 15.7495Z" fill="#374957"/>
</svg>

  )
}

export default DeleteIconSvg