import './ErrorPage.css';

const ErrorPage = () => {
  return(
    <div>
      ErrorPage
    </div>
  )
}

export default ErrorPage;
