import React from 'react'

function UploadPicSvg() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_77_1204)">
<path d="M1 3.5L1 2.5C1 2.10217 1.15804 1.72064 1.43934 1.43934C1.72064 1.15803 2.10218 1 2.5 1L9.5 1C9.89782 1 10.2794 1.15804 10.5607 1.43934C10.842 1.72064 11 2.10218 11 2.5L11 3.5C11 3.63261 11.0527 3.75979 11.1464 3.85355C11.2402 3.94732 11.3674 4 11.5 4C11.6326 4 11.7598 3.94732 11.8536 3.85355C11.9473 3.75979 12 3.63261 12 3.5L12 2.5C11.9992 1.8372 11.7356 1.20178 11.2669 0.73311C10.7982 0.264441 10.1628 0.000793848 9.5 -1.09278e-07L2.5 -4.15258e-07C1.8372 0.000793485 1.20178 0.264441 0.733109 0.733109C0.26444 1.20178 0.000793377 1.8372 -1.09278e-07 2.5L-1.5299e-07 3.5C-1.58786e-07 3.63261 0.0526779 3.75978 0.146446 3.85355C0.240214 3.94732 0.367391 4 0.5 4C0.632608 4 0.759785 3.94732 0.853554 3.85355C0.947322 3.75978 1 3.63261 1 3.5Z" fill="#374957"/>
<path d="M6.49971 11.5L6.48372 3.61153C6.56517 3.65538 6.64021 3.71023 6.70672 3.77453L8.64622 5.71403C8.69234 5.76179 8.74751 5.79988 8.80851 5.82608C8.86951 5.85229 8.93512 5.86608 9.00151 5.86666C9.0679 5.86723 9.13374 5.85458 9.19519 5.82944C9.25664 5.8043 9.31247 5.76717 9.35941 5.72023C9.40636 5.67328 9.44349 5.61746 9.46863 5.55601C9.49377 5.49456 9.50642 5.42872 9.50584 5.36233C9.50526 5.29594 9.49147 5.23033 9.46527 5.16933C9.43906 5.10833 9.40097 5.05315 9.35322 5.00703L7.41372 3.06653C7.03866 2.69159 6.53004 2.48096 5.99972 2.48096C5.46939 2.48096 4.96077 2.69159 4.58572 3.06653L2.64621 5.00603C2.59846 5.05215 2.56037 5.10733 2.53416 5.16833C2.50796 5.22933 2.49417 5.29494 2.49359 5.36133C2.49301 5.42772 2.50566 5.49356 2.5308 5.55501C2.55594 5.61645 2.59307 5.67228 2.64002 5.71923C2.68696 5.76617 2.74279 5.8033 2.80424 5.82844C2.86569 5.85358 2.93153 5.86623 2.99792 5.86566C3.06431 5.86508 3.12992 5.85129 3.19092 5.82508C3.25192 5.79888 3.30709 5.76078 3.35322 5.71303L5.29272 3.77353C5.35034 3.71757 5.41446 3.66872 5.48372 3.62803L5.49971 11.5C5.49971 11.6326 5.55239 11.7598 5.64616 11.8536C5.73993 11.9474 5.86711 12 5.99971 12C6.13232 12 6.2595 11.9474 6.35327 11.8536C6.44704 11.7598 6.49971 11.6326 6.49971 11.5Z" fill="#374957"/>
</g>
<defs>
<clipPath id="clip0_77_1204">
<rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"/>
</clipPath>
</defs>
</svg>

  )
}

export default UploadPicSvg