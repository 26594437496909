import './SchedulePage.css';

const SchedulePage = () => {
  return(
    <div>
      SchedulePage
    </div>
  )
}

export default SchedulePage;
