import React from 'react'

function ThreedotSvg() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.50004 2.83333C9.28244 2.83333 9.9167 2.19907 9.9167 1.41667C9.9167 0.634263 9.28244 0 8.50004 0C7.71764 0 7.08337 0.634263 7.08337 1.41667C7.08337 2.19907 7.71764 2.83333 8.50004 2.83333Z" fill="#374957"/>
<path d="M8.50004 9.91682C9.28244 9.91682 9.9167 9.28256 9.9167 8.50016C9.9167 7.71776 9.28244 7.0835 8.50004 7.0835C7.71764 7.0835 7.08337 7.71776 7.08337 8.50016C7.08337 9.28256 7.71764 9.91682 8.50004 9.91682Z" fill="#374957"/>
<path d="M8.50004 16.9999C9.28244 16.9999 9.9167 16.3656 9.9167 15.5832C9.9167 14.8008 9.28244 14.1665 8.50004 14.1665C7.71764 14.1665 7.08337 14.8008 7.08337 15.5832C7.08337 16.3656 7.71764 16.9999 8.50004 16.9999Z" fill="#374957"/>
</svg>

  )
}

export default ThreedotSvg