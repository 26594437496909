 const LoginSvg=()=>{
    return(
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z" fill="url(#paint0_linear_3_798)"/>
<path opacity="0.2" d="M18.35 79.7512C20.7746 79.7512 22.7402 78.004 22.7402 75.8487C22.7402 73.6935 20.7746 71.9463 18.35 71.9463C15.9253 71.9463 13.9597 73.6935 13.9597 75.8487C13.9597 78.004 15.9253 79.7512 18.35 79.7512Z" fill="url(#paint1_linear_3_798)"/>
<path opacity="0.2" d="M128.27 38.553C131.503 38.553 134.123 35.9322 134.123 32.6993C134.123 29.4664 131.503 26.8456 128.27 26.8456C125.037 26.8456 122.416 29.4664 122.416 32.6993C122.416 35.9322 125.037 38.553 128.27 38.553Z" fill="url(#paint2_linear_3_798)"/>
<path opacity="0.2" d="M48.9049 130.025C51.599 130.025 53.783 127.841 53.783 125.147C53.783 122.452 51.599 120.268 48.9049 120.268C46.2108 120.268 44.0269 122.452 44.0269 125.147C44.0269 127.841 46.2108 130.025 48.9049 130.025Z" fill="url(#paint3_linear_3_798)"/>
<path opacity="0.2" d="M98.5366 130.732C100.153 130.732 101.463 129.421 101.463 127.805C101.463 126.188 100.153 124.878 98.5366 124.878C96.9201 124.878 95.6097 126.188 95.6097 127.805C95.6097 129.421 96.9201 130.732 98.5366 130.732Z" fill="url(#paint4_linear_3_798)"/>
<path d="M132.534 62.464L132.603 62.1508C132.693 61.7755 132.707 61.3863 132.645 61.0055C132.583 60.6247 132.446 60.2597 132.242 59.9314C132.038 59.6032 131.77 59.3182 131.455 59.0926C131.139 58.8671 130.782 58.7055 130.403 58.6172L38.5723 37.154C38.1933 37.0639 37.8001 37.049 37.4152 37.11C37.0304 37.1709 36.6614 37.3067 36.3296 37.5093C35.9977 37.712 35.7094 37.9777 35.4812 38.2911C35.253 38.6045 35.0893 38.9595 34.9997 39.3358L34.9207 39.675L22.5875 91.6941L22.5191 92.0124C22.4292 92.3877 22.4148 92.777 22.4767 93.1578C22.5386 93.5386 22.6756 93.9036 22.8799 94.2318C23.0842 94.5601 23.3518 94.8451 23.6672 95.0706C23.9827 95.2962 24.3399 95.4578 24.7185 95.5462L25.0289 95.6192H25.0605L116.197 116.926L116.518 116.994C116.896 117.083 117.289 117.097 117.673 117.036C118.056 116.974 118.424 116.838 118.755 116.636C119.086 116.433 119.373 116.168 119.601 115.855C119.828 115.542 119.991 115.187 120.08 114.812L120.159 114.473L132.513 62.4536L132.534 62.464Z" fill="#3F943E"/>
<path d="M134.554 62.1402L134.479 62.4481C134.59 61.9769 134.592 61.4873 134.486 61.0152C134.379 60.543 134.167 60.1002 133.864 59.7192L79.056 77.0732L37.901 37.4659C37.4521 37.6783 37.0573 37.9868 36.7453 38.3686C36.4332 38.7505 36.2119 39.1961 36.0975 39.673L36.1771 39.3338C36.3609 38.575 36.8435 37.9189 37.5189 37.51C38.1942 37.101 39.007 36.9725 39.7786 37.1529L132.331 58.6079C132.713 58.6957 133.074 58.8568 133.393 59.082C133.711 59.3071 133.981 59.592 134.188 59.9202C134.394 60.2483 134.533 60.6134 134.596 60.9943C134.658 61.3753 134.644 61.7647 134.554 62.1402Z" fill="#3F943E"/>
<path d="M133.94 60.3953C134.24 60.7783 134.451 61.2234 134.556 61.6979C134.662 62.1725 134.66 62.6646 134.55 63.1382C134.55 63.1382 134.55 63.1382 134.55 63.1697L122.189 115.436L122.11 115.777C122.02 116.154 121.856 116.51 121.628 116.825C121.4 117.139 121.112 117.406 120.781 117.61C120.449 117.813 120.081 117.95 119.696 118.011C119.312 118.073 118.919 118.058 118.54 117.969L118.224 117.901C118.22 117.899 118.216 117.898 118.211 117.898C118.207 117.898 118.202 117.899 118.198 117.901L27.013 96.4823L26.6761 96.4037C26.2969 96.3154 25.9389 96.1535 25.6226 95.9271C25.3064 95.7008 25.0381 95.4145 24.8331 95.0846C24.6282 94.7548 24.4906 94.3879 24.4284 94.0049C24.3661 93.622 24.3803 93.2306 24.4702 92.8532L24.5439 92.5333L36.9054 40.2672C37.019 39.7879 37.2386 39.34 37.5483 38.9561C37.8581 38.5723 38.25 38.2623 38.6954 38.0488" fill="#3F943E"/>
<path d="M79.0244 77.3848L25.0798 94.6341C24.7812 94.2538 24.5719 93.812 24.4671 93.3411C24.3623 92.8703 24.3647 92.3821 24.4741 91.9122L36.7333 40.2419C36.8459 39.7681 37.0637 39.3252 37.3709 38.9458C37.678 38.5663 38.0667 38.2599 38.5085 38.0488L79.0244 77.3848Z" fill="url(#paint5_linear_3_798)"/>
<path d="M134.549 62.27C134.549 62.27 134.549 62.27 134.549 62.3017L122.071 114.853C121.957 115.334 121.735 115.783 121.422 116.167C121.11 116.552 120.714 116.861 120.264 117.073L79.0243 77.0505L133.933 59.5122C134.236 59.8973 134.449 60.3448 134.556 60.8219C134.662 61.2991 134.66 61.7938 134.549 62.27V62.27Z" fill="url(#paint6_linear_3_798)"/>
<path d="M78.9935 77.0732L24.3903 94.4597C24.8552 95.0619 25.5191 95.4836 26.2662 95.6513L117.791 116.983C118.533 117.16 119.314 117.075 120 116.743L78.9935 77.0732Z" fill="url(#paint7_linear_3_798)"/>
<path d="M38.049 37.561L76.2569 78.712C76.809 79.2994 77.5311 79.7115 78.3289 79.8943C79.1267 80.0771 79.9628 80.0221 80.7277 79.7365L133.659 59.8967" fill="url(#paint8_linear_3_798)"/>
<path d="M79.0244 89.7561C86.0289 89.7561 91.7073 84.2961 91.7073 77.561C91.7073 70.8258 86.0289 65.3658 79.0244 65.3658C72.0198 65.3658 66.3414 70.8258 66.3414 77.561C66.3414 84.2961 72.0198 89.7561 79.0244 89.7561Z" fill="#C6E9C6"/>
<path d="M79.9999 89.7561C87.0045 89.7561 92.6829 84.5146 92.6829 78.0488C92.6829 71.583 87.0045 66.3415 79.9999 66.3415C72.9954 66.3415 67.317 71.583 67.317 78.0488C67.317 84.5146 72.9954 89.7561 79.9999 89.7561Z" fill="white"/>
<path d="M76.9665 88.4899C74.8045 87.9862 72.8405 86.8523 71.323 85.2318C69.8056 83.6114 68.8029 81.5771 68.4419 79.3865C68.081 77.1959 68.3779 74.9475 69.2951 72.9258C70.2124 70.904 71.7087 69.1998 73.5948 68.0289C75.4809 66.858 77.6719 66.273 79.8906 66.3478C82.1092 66.4227 84.2558 67.1542 86.0587 68.4496C87.8615 69.7451 89.2396 71.5463 90.0184 73.6253C90.7973 75.7043 90.9419 77.9676 90.4341 80.1289C89.7518 83.0207 87.9514 85.5243 85.4272 87.0914C82.903 88.6585 79.8607 89.1614 76.9665 88.4899V88.4899ZM82.0013 67.0072C79.9126 66.5175 77.7255 66.6584 75.7168 67.4119C73.708 68.1654 71.9678 69.4977 70.7163 71.2404C69.4647 72.983 68.7581 75.0577 68.6856 77.2021C68.6131 79.3465 69.1782 81.4641 70.3092 83.2873C71.4403 85.1105 73.0865 86.5573 75.0398 87.4447C76.9931 88.3321 79.1657 88.6203 81.2827 88.2728C83.3998 87.9253 85.3663 86.9577 86.9334 85.4924C88.5006 84.0271 89.5981 82.1299 90.087 80.0408C90.734 77.2407 90.2468 74.2984 88.7318 71.8563C87.2169 69.4143 84.7972 67.671 82.0013 67.0072V67.0072Z" fill="#74B973"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M77.0416 82.9163C76.9036 82.9342 76.7626 82.9293 76.6253 82.9011V82.9213C76.459 82.885 76.3034 82.8153 76.1692 82.7173C76.0351 82.6192 75.9258 82.4951 75.8489 82.3537L73.2955 77.6653C73.2266 77.5385 73.1853 77.4004 73.1739 77.2588C73.1626 77.1172 73.1815 76.9749 73.2294 76.8401C73.2774 76.7052 73.3536 76.5805 73.4536 76.4729C73.5536 76.3654 73.6754 76.2771 73.8122 76.2132C73.949 76.1493 74.098 76.111 74.2508 76.1005C74.4035 76.09 74.557 76.1075 74.7025 76.152C74.8479 76.1964 74.9825 76.267 75.0985 76.3597C75.2146 76.4524 75.3098 76.5654 75.3787 76.6922L77.3678 80.3366L86.1425 74.3111C86.3835 74.1598 86.6722 74.1102 86.948 74.1729C87.2237 74.2356 87.4652 74.4057 87.6217 74.6474C87.7782 74.8892 87.8376 75.1839 87.7875 75.4697C87.7373 75.7556 87.5815 76.0103 87.3527 76.1806L77.7784 82.7498C77.6555 82.8309 77.5181 82.8857 77.3742 82.9109C77.264 82.9303 77.1519 82.932 77.0416 82.9163Z" fill="#74B973"/>
<path opacity="0.2" d="M47.8047 28.7707C51.0376 28.7707 53.6584 26.1499 53.6584 22.917C53.6584 19.6841 51.0376 17.0634 47.8047 17.0634C44.5718 17.0634 41.951 19.6841 41.951 22.917C41.951 26.1499 44.5718 28.7707 47.8047 28.7707Z" fill="url(#paint9_linear_3_798)"/>
<path opacity="0.2" d="M143.909 97.561C146.334 97.561 148.299 95.5955 148.299 93.1708C148.299 90.7461 146.334 88.7805 143.909 88.7805C141.484 88.7805 139.519 90.7461 139.519 93.1708C139.519 95.5955 141.484 97.561 143.909 97.561Z" fill="url(#paint10_linear_3_798)"/>
<path opacity="0.2" d="M91.8579 39.8232C94.552 39.8232 96.736 37.6392 96.736 34.9452C96.736 32.2511 94.552 30.0671 91.8579 30.0671C89.1638 30.0671 86.9799 32.2511 86.9799 34.9452C86.9799 37.6392 89.1638 39.8232 91.8579 39.8232Z" fill="url(#paint11_linear_3_798)"/>
<path d="M132.807 93.8495C125.472 94.2683 118.58 91.3961 115.728 90.0109C115.311 89.8107 114.848 89.7259 114.387 89.7656C113.926 89.8053 113.484 89.968 113.108 90.2364C110.532 92.0779 104.234 96.0722 96.9041 96.8936C96.2362 96.9706 95.6254 97.3052 95.2032 97.8253C94.7809 98.3454 94.581 99.0094 94.6463 99.6746C95.2891 106.337 98.692 124.097 116.722 134.297C117.175 134.555 117.696 134.669 118.216 134.625C118.736 134.581 119.231 134.38 119.633 134.05C135.665 120.956 135.994 102.912 135.486 96.2064C135.435 95.5447 135.127 94.9287 134.627 94.4888C134.127 94.049 133.474 93.8197 132.807 93.8495Z" fill="#C27500"/>
<path d="M132.881 92.8784C125.716 93.2918 118.988 90.4197 116.204 89.0401C115.798 88.8374 115.346 88.7507 114.896 88.7895C114.445 88.8283 114.014 88.991 113.647 89.2602C111.132 91.1069 104.984 95.0955 97.8297 95.9223C97.1784 95.9993 96.5825 96.3329 96.1697 96.8516C95.7568 97.3703 95.5597 98.0329 95.6206 98.6977C96.248 105.365 99.5696 123.118 117.169 133.323C117.613 133.58 118.122 133.693 118.63 133.649C119.139 133.605 119.622 133.405 120.016 133.076C135.665 119.983 135.981 101.908 135.491 95.2351C135.442 94.5739 135.143 93.958 134.655 93.518C134.168 93.0781 133.532 92.8487 132.881 92.8784V92.8784Z" fill="url(#paint12_linear_3_798)"/>
<path d="M131.241 95.2695C124.95 95.6315 119.04 93.1396 116.6 91.9309C116.242 91.7553 115.844 91.6809 115.448 91.7156C115.052 91.7503 114.672 91.8928 114.35 92.1279C112.142 93.7253 106.744 97.197 100.48 97.9159C99.9079 97.9829 99.3847 98.2738 99.0231 98.7259C98.6616 99.1781 98.4906 99.7553 98.5471 100.333C99.0753 106.127 102.012 121.568 117.466 130.439C117.855 130.663 118.302 130.762 118.748 130.724C119.194 130.686 119.618 130.512 119.964 130.226C133.697 118.837 133.987 103.123 133.554 97.3195C133.511 96.7408 133.244 96.2022 132.812 95.819C132.379 95.4358 131.815 95.2386 131.241 95.2695Z" fill="url(#paint13_linear_3_798)"/>
<path d="M117.073 119.024C122.461 119.024 126.829 114.656 126.829 109.268C126.829 103.88 122.461 99.5122 117.073 99.5122C111.685 99.5122 107.317 103.88 107.317 109.268C107.317 114.656 111.685 119.024 117.073 119.024Z" fill="#FFD18C"/>
<path d="M118.414 119.963C116.395 120.139 114.372 119.685 112.6 118.657C110.828 117.63 109.386 116.075 108.457 114.189C107.529 112.303 107.154 110.172 107.382 108.063C107.609 105.955 108.428 103.964 109.735 102.343C111.042 100.722 112.779 99.5436 114.725 98.9561C116.671 98.3686 118.74 98.3987 120.67 99.0427C122.6 99.6866 124.305 100.915 125.568 102.574C126.832 104.232 127.598 106.246 127.769 108.36C127.995 111.198 127.137 114.013 125.383 116.189C123.629 118.364 121.122 119.722 118.414 119.963ZM116.792 100.04C115.052 100.2 113.396 100.895 112.034 102.04C110.671 103.184 109.663 104.726 109.137 106.47C108.61 108.214 108.588 110.083 109.075 111.84C109.561 113.597 110.534 115.163 111.87 116.342C113.206 117.52 114.845 118.257 116.581 118.46C118.316 118.663 120.07 118.323 121.621 117.482C123.172 116.642 124.451 115.338 125.295 113.737C126.14 112.136 126.512 110.308 126.366 108.485C126.268 107.271 125.943 106.09 125.409 105.008C124.875 103.926 124.142 102.965 123.252 102.181C122.363 101.396 121.334 100.803 120.226 100.436C119.117 100.069 117.95 99.9343 116.792 100.04V100.04Z" fill="url(#paint14_linear_3_798)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.014 108.655C119.619 108.208 120 107.556 120 106.829C120 105.482 118.69 104.39 117.073 104.39C115.457 104.39 114.146 105.482 114.146 106.829C114.146 107.833 114.873 108.694 115.912 109.069L116.449 114.01C116.49 114.334 116.691 114.632 117.009 114.839C117.326 115.046 117.735 115.146 118.145 115.117C118.555 115.083 118.931 114.924 119.193 114.673C119.456 114.423 119.583 114.101 119.548 113.777L119.014 108.655Z" fill="url(#paint15_linear_3_798)"/>
<defs>
<linearGradient id="paint0_linear_3_798" x1="80" y1="0" x2="80" y2="206.174" gradientUnits="userSpaceOnUse">
<stop stop-color="#74B973"/>
<stop offset="1" stop-color="#F3FBF2"/>
</linearGradient>
<linearGradient id="paint1_linear_3_798" x1="18.35" y1="71.9463" x2="18.35" y2="82.4052" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_3_798" x1="128.27" y1="26.8456" x2="128.27" y2="42.534" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_3_798" x1="48.9049" y1="120.268" x2="48.9049" y2="133.342" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_3_798" x1="98.5366" y1="124.878" x2="98.5366" y2="132.722" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_3_798" x1="51.7073" y1="38.0488" x2="51.7073" y2="94.6341" gradientUnits="userSpaceOnUse">
<stop stop-color="#8CCC8B"/>
<stop offset="1" stop-color="#74B973"/>
</linearGradient>
<linearGradient id="paint6_linear_3_798" x1="106.829" y1="59.5122" x2="106.829" y2="117.073" gradientUnits="userSpaceOnUse">
<stop stop-color="#8CCC8B"/>
<stop offset="1" stop-color="#74B973"/>
</linearGradient>
<linearGradient id="paint7_linear_3_798" x1="72.1951" y1="77.0732" x2="72.1951" y2="117.073" gradientUnits="userSpaceOnUse">
<stop stop-color="#74B973"/>
<stop offset="1" stop-color="#489647"/>
</linearGradient>
<linearGradient id="paint8_linear_3_798" x1="85.8538" y1="37.561" x2="76.7786" y2="88.0537" gradientUnits="userSpaceOnUse">
<stop stop-color="#B4EAB3"/>
<stop offset="1" stop-color="#8CCC8B"/>
</linearGradient>
<linearGradient id="paint9_linear_3_798" x1="47.8047" y1="17.0634" x2="47.8047" y2="32.7517" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_3_798" x1="143.909" y1="88.7805" x2="143.909" y2="100.547" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_3_798" x1="91.8579" y1="30.0671" x2="91.8579" y2="43.1407" gradientUnits="userSpaceOnUse">
<stop stop-color="#85C284"/>
<stop offset="1" stop-color="#85C284" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint12_linear_3_798" x1="115.61" y1="88.7805" x2="115.61" y2="133.659" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA620"/>
<stop offset="1" stop-color="#F39200"/>
</linearGradient>
<linearGradient id="paint13_linear_3_798" x1="116.097" y1="91.7073" x2="116.097" y2="130.732" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFD18C"/>
<stop offset="1" stop-color="#F4B75B"/>
</linearGradient>
<linearGradient id="paint14_linear_3_798" x1="117.561" y1="98.5366" x2="117.561" y2="120" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA620"/>
<stop offset="1" stop-color="#F39200"/>
</linearGradient>
<linearGradient id="paint15_linear_3_798" x1="117.073" y1="104.39" x2="117.073" y2="115.122" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA620"/>
<stop offset="1" stop-color="#F39200"/>
</linearGradient>
</defs>
</svg>


    )

}
export default LoginSvg