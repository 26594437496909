import React from 'react'

function DeleteSvg() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="37" height="37" rx="18.5" fill="url(#paint0_linear_100_2058)"/>
<g clip-path="url(#clip0_100_2058)">
<path d="M26.375 11.8333H23.6625C23.2439 9.89465 21.4532 8.5025 19.375 8.5H17.625C15.5467 8.5025 13.7561 9.89465 13.3375 11.8333H10.625C10.1417 11.8333 9.75 12.2064 9.75 12.6666C9.75 13.1269 10.1417 13.5 10.625 13.5H11.5V24.3333C11.5029 26.6334 13.4599 28.4973 15.875 28.5H21.125C23.54 28.4973 25.4971 26.6334 25.5 24.3333V13.5H26.375C26.8582 13.5 27.25 13.1269 27.25 12.6667C27.25 12.2064 26.8582 11.8333 26.375 11.8333ZM17.625 22.6667C17.625 23.1269 17.2333 23.5 16.75 23.5C16.2667 23.5 15.875 23.1269 15.875 22.6667V17.6667C15.875 17.2064 16.2667 16.8334 16.75 16.8334C17.2332 16.8334 17.625 17.2064 17.625 17.6667V22.6667H17.625ZM21.125 22.6667C21.125 23.1269 20.7332 23.5 20.25 23.5C19.7668 23.5 19.375 23.1269 19.375 22.6667V17.6667C19.375 17.2064 19.7668 16.8334 20.25 16.8334C20.7332 16.8334 21.125 17.2064 21.125 17.6667V22.6667ZM15.1496 11.8333C15.5218 10.8352 16.5129 10.1679 17.625 10.1666H19.375C20.4871 10.1679 21.4782 10.8352 21.8504 11.8333H15.1496Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_100_2058" x1="4" y1="7.00001" x2="43.1065" y2="8.83" gradientUnits="userSpaceOnUse">
<stop stop-color="#DD5555"/>
<stop offset="1" stop-color="#BF1C1C"/>
</linearGradient>
<clipPath id="clip0_100_2058">
<rect width="21" height="20" fill="white" transform="translate(8 8.5)"/>
</clipPath>
</defs>
</svg>

  )
}

export default DeleteSvg