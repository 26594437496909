import React from 'react'

function LogoutSvg() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_64_1583)">
<path d="M10.0415 13.125C9.80944 13.125 9.58688 13.2172 9.42278 13.3813C9.25869 13.5454 9.1665 13.7679 9.1665 14V16.625C9.1665 17.3212 8.88994 17.9889 8.39766 18.4812C7.90537 18.9734 7.23769 19.25 6.5415 19.25H4.375C3.67881 19.25 3.01113 18.9734 2.51884 18.4812C2.02656 17.9889 1.75 17.3212 1.75 16.625V4.375C1.75 3.67881 2.02656 3.01113 2.51884 2.51884C3.01113 2.02656 3.67881 1.75 4.375 1.75H6.5415C7.23769 1.75 7.90537 2.02656 8.39766 2.51884C8.88994 3.01113 9.1665 3.67881 9.1665 4.375V7C9.1665 7.23206 9.25869 7.45462 9.42278 7.61872C9.58688 7.78281 9.80944 7.875 10.0415 7.875C10.2736 7.875 10.4961 7.78281 10.6602 7.61872C10.8243 7.45462 10.9165 7.23206 10.9165 7V4.375C10.9151 3.2151 10.4537 2.10311 9.63356 1.28294C8.81339 0.462772 7.7014 0.00138938 6.5415 0H4.375C3.2151 0.00138938 2.10311 0.462772 1.28294 1.28294C0.462772 2.10311 0.00138938 3.2151 0 4.375L0 16.625C0.00138938 17.7849 0.462772 18.8969 1.28294 19.7171C2.10311 20.5372 3.2151 20.9986 4.375 21H6.5415C7.7014 20.9986 8.81339 20.5372 9.63356 19.7171C10.4537 18.8969 10.9151 17.7849 10.9165 16.625V14C10.9165 13.7679 10.8243 13.5454 10.6602 13.3813C10.4961 13.2172 10.2736 13.125 10.0415 13.125Z" fill="#CB4A4A"/>
<path d="M20.0087 8.64413L15.996 4.63139C15.9153 4.54781 15.8187 4.48115 15.712 4.4353C15.6052 4.38944 15.4904 4.3653 15.3742 4.36429C15.258 4.36328 15.1428 4.38542 15.0353 4.42942C14.9278 4.47341 14.8301 4.53838 14.7479 4.62054C14.6657 4.7027 14.6008 4.80039 14.5568 4.90793C14.5128 5.01546 14.4906 5.13068 14.4917 5.24686C14.4927 5.36304 14.5168 5.47786 14.5627 5.58461C14.6085 5.69137 14.6752 5.78792 14.7587 5.86864L18.488 9.59876L5.25012 9.62501C5.01806 9.62501 4.7955 9.7172 4.6314 9.88129C4.46731 10.0454 4.37512 10.2679 4.37512 10.5C4.37512 10.7321 4.46731 10.9546 4.6314 11.1187C4.7955 11.2828 5.01806 11.375 5.25012 11.375L18.5396 11.3479L14.757 15.1314C14.6734 15.2121 14.6068 15.3087 14.5609 15.4154C14.5151 15.5222 14.4909 15.637 14.4899 15.7532C14.4889 15.8693 14.511 15.9846 14.555 16.0921C14.599 16.1996 14.664 16.2973 14.7462 16.3795C14.8283 16.4616 14.926 16.5266 15.0335 16.5706C15.1411 16.6146 15.2563 16.6367 15.3725 16.6357C15.4887 16.6347 15.6035 16.6106 15.7102 16.5647C15.817 16.5189 15.9135 16.4522 15.9942 16.3686L20.007 12.3559C20.4993 11.8639 20.7761 11.1964 20.7764 10.5004C20.7768 9.80432 20.5006 9.13663 20.0087 8.64413Z" fill="#CB4A4A"/>
</g>
<defs>
<clipPath id="clip0_64_1583">
<rect width="21" height="21" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default LogoutSvg