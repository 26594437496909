const OtpSvg=()=>{
    return(
        <svg width="158" height="170" viewBox="0 0 158 170" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M79 158C122.63 158 158 122.631 158 79C158 35.3695 122.63 0 79 0C35.3695 0 0 35.3695 0 79C0 122.631 35.3695 158 79 158Z" fill="#FFF3CD"/>
<path d="M29.6154 136.154C32.1644 136.154 34.2308 134.087 34.2308 131.538C34.2308 128.989 32.1644 126.923 29.6154 126.923C27.0664 126.923 25 128.989 25 131.538C25 134.087 27.0664 136.154 29.6154 136.154Z" fill="white"/>
<path d="M12.8285 73.5435C15.3775 73.5435 17.4439 71.4771 17.4439 68.9281C17.4439 66.3791 15.3775 64.3127 12.8285 64.3127C10.2795 64.3127 8.21313 66.3791 8.21313 68.9281C8.21313 71.4771 10.2795 73.5435 12.8285 73.5435Z" fill="#FEE28C"/>
<path d="M128.808 74.3327C131.357 74.3327 133.423 72.2663 133.423 69.7173C133.423 67.1683 131.357 65.1019 128.808 65.1019C126.259 65.1019 124.193 67.1683 124.193 69.7173C124.193 72.2663 126.259 74.3327 128.808 74.3327Z" fill="white"/>
<g opacity="0.4" filter="url(#filter0_f_3_841)">
<path d="M99.2689 39H55.7311C52.0136 39 49 42.0351 49 45.7791V129.221C49 132.965 52.0136 136 55.7311 136H99.2689C102.986 136 106 132.965 106 129.221V45.7791C106 42.0351 102.986 39 99.2689 39Z" fill="#DEA028"/>
</g>
<path d="M100.796 26H54.2035C50.2251 26 47 29.1916 47 33.1286V120.871C47 124.808 50.2251 128 54.2035 128H100.796C104.775 128 108 124.808 108 120.871V33.1286C108 29.1916 104.775 26 100.796 26Z" fill="white"/>
<path d="M99.0377 29H55.9623C53.2217 29 51 31.3222 51 34.1868V119.813C51 122.678 53.2217 125 55.9623 125H99.0377C101.778 125 104 122.678 104 119.813V34.1868C104 31.3222 101.778 29 99.0377 29Z" fill="#F5C151"/>
<path d="M67 26.7826V30.0259C67 30.417 67.069 30.8043 67.2031 31.1655C67.3373 31.5267 67.5338 31.8549 67.7816 32.1311C68.0293 32.4074 68.3234 32.6263 68.647 32.7754C68.9706 32.9245 69.3173 33.0008 69.6673 33H86.3383C86.688 33.0008 87.0345 32.9245 87.3577 32.7753C87.681 32.6261 87.9747 32.4071 88.222 32.1308C88.4693 31.8544 88.6654 31.5262 88.7989 31.165C88.9324 30.8038 89.0007 30.4167 89 30.0259V26.7826C89 26.5751 88.9262 26.376 88.7948 26.2292C88.6635 26.0825 88.4853 26 88.2995 26H67.7061C67.5198 26 67.341 26.0823 67.2088 26.2289C67.0765 26.3754 67.0015 26.5745 67 26.7826Z" fill="white"/>
<path d="M135.539 135C139.362 135 142.462 131.9 142.462 128.077C142.462 124.253 139.362 121.154 135.539 121.154C131.715 121.154 128.615 124.253 128.615 128.077C128.615 131.9 131.715 135 135.539 135Z" fill="#FEE28C"/>
<path d="M77.923 88.5125L87.3162 105.538L89.3529 98.5776L97.3846 101.416L87.9969 84.3846L77.923 88.5125Z" fill="#FF8080"/>
<path d="M87.6337 83.5384L82.1538 85.7957L89.0422 98.3461L97.3846 101.308L87.6337 83.5384Z" fill="#EA5046"/>
<path d="M77.923 88.5125L68.1245 105.538L65.9928 98.5776L57.6154 101.416L67.4139 84.3846L77.923 88.5125Z" fill="#FF8080"/>
<path d="M67.3662 84.3846L72.8461 86.5344L65.9521 98.4872L57.6154 101.308L67.3662 84.3846Z" fill="#EA5046"/>
<path d="M77.5 92C88.2696 92 97 83.0457 97 72C97 60.9543 88.2696 52 77.5 52C66.7305 52 58 60.9543 58 72C58 83.0457 66.7305 92 77.5 92Z" fill="#FFEFBE"/>
<path opacity="0.4" d="M77.5 89C86.0604 89 93 81.6127 93 72.5C93 63.3873 86.0604 56 77.5 56C68.9396 56 62 63.3873 62 72.5C62 81.6127 68.9396 89 77.5 89Z" stroke="#F5C151" stroke-width="3" stroke-miterlimit="10"/>
<path d="M73.6967 79.9998C73.516 79.9962 73.3381 79.9556 73.1746 79.8807C73.011 79.8057 72.8654 79.6981 72.7473 79.5648L67.3203 73.5084C67.0939 73.2557 66.9797 72.9256 67.003 72.5908C67.0262 72.256 67.185 71.944 67.4443 71.7233C67.7036 71.5026 68.0422 71.3913 68.3857 71.414C68.7291 71.4366 69.0493 71.5913 69.2757 71.8441L73.7605 76.8508L84.82 65.3388C85.0601 65.121 85.376 64.9999 85.704 65C86.032 65.0001 86.3478 65.1215 86.5877 65.3396C86.8276 65.5576 86.9738 65.8562 86.9968 66.1752C87.0198 66.4941 86.9179 66.8097 86.7116 67.0584L74.6673 79.5993C74.5431 79.7283 74.3925 79.8305 74.2254 79.8995C74.0582 79.9685 73.8782 80.0026 73.6967 79.9998Z" fill="#041724"/>
<path d="M54.1922 20.2307C55.7854 20.2307 57.0768 18.9392 57.0768 17.346C57.0768 15.7529 55.7854 14.4614 54.1922 14.4614C52.5991 14.4614 51.3076 15.7529 51.3076 17.346C51.3076 18.9392 52.5991 20.2307 54.1922 20.2307Z" fill="white"/>
<path d="M126.75 125C126.75 135.355 118.355 143.75 108 143.75C97.6447 143.75 89.25 135.355 89.25 125C89.25 114.645 97.6447 106.25 108 106.25C118.355 106.25 126.75 114.645 126.75 125Z" fill="#3B5059" stroke="white" stroke-width="2.5"/>
<path d="M107.5 123.545C110.108 123.545 112.222 121.409 112.222 118.773C112.222 116.137 110.108 114 107.5 114C104.892 114 102.778 116.137 102.778 118.773C102.778 121.409 104.892 123.545 107.5 123.545Z" fill="white"/>
<path d="M111.276 125.644C111.046 125.512 110.793 125.448 110.538 125.455C110.283 125.462 110.033 125.541 109.808 125.685C109.088 126.124 108.291 126.352 107.481 126.352C106.672 126.352 105.875 126.124 105.155 125.685C104.93 125.541 104.68 125.462 104.425 125.455C104.17 125.448 103.917 125.512 103.687 125.644C102.534 126.324 101.52 127.297 100.718 128.495C99.9161 129.692 99.3458 131.085 99.0476 132.573C98.9884 132.86 98.9844 133.158 99.0358 133.447C99.0872 133.735 99.1928 134.006 99.3446 134.239C99.4964 134.472 99.6905 134.662 99.9126 134.794C100.135 134.925 100.379 134.996 100.627 135H114.371C114.62 134.998 114.865 134.928 115.087 134.797C115.31 134.666 115.505 134.476 115.657 134.242C115.809 134.009 115.914 133.737 115.965 133.448C116.016 133.159 116.011 132.86 115.951 132.573C115.65 131.08 115.075 129.684 114.266 128.486C113.458 127.288 112.437 126.318 111.276 125.644Z" fill="white"/>
<defs>
<filter id="filter0_f_3_841" x="15" y="5" width="125" height="165" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="17" result="effect1_foregroundBlur_3_841"/>
</filter>
</defs>
</svg>

    )
}

export default OtpSvg