import React from 'react'

function GreenTickSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_64_1365)">
<path d="M18.1913 7.4588L9.8999 15.7496C9.84416 15.8056 9.77791 15.85 9.70496 15.8803C9.63201 15.9106 9.55379 15.9262 9.4748 15.9262C9.39581 15.9262 9.31759 15.9106 9.24464 15.8803C9.1717 15.85 9.10545 15.8056 9.0497 15.7496L5.8433 12.5402C5.78756 12.4842 5.72131 12.4398 5.64836 12.4095C5.57541 12.3792 5.49719 12.3636 5.4182 12.3636C5.33921 12.3636 5.26099 12.3792 5.18805 12.4095C5.1151 12.4398 5.04885 12.4842 4.9931 12.5402C4.93714 12.5959 4.89273 12.6622 4.86243 12.7351C4.83213 12.8081 4.81653 12.8863 4.81653 12.9653C4.81653 13.0443 4.83213 13.1225 4.86243 13.1955C4.89273 13.2684 4.93714 13.3347 4.9931 13.3904L8.2007 16.5974C8.53907 16.9351 8.99762 17.1248 9.4757 17.1248C9.95378 17.1248 10.4123 16.9351 10.7507 16.5974L19.0415 8.3084C19.0974 8.25267 19.1417 8.18645 19.172 8.11356C19.2022 8.04067 19.2178 7.96252 19.2178 7.8836C19.2178 7.80468 19.2022 7.72653 19.172 7.65364C19.1417 7.58075 19.0974 7.51454 19.0415 7.4588C18.9858 7.40283 18.9195 7.35843 18.8466 7.32813C18.7736 7.29782 18.6954 7.28223 18.6164 7.28223C18.5374 7.28223 18.4592 7.29782 18.3862 7.32813C18.3133 7.35843 18.247 7.40283 18.1913 7.4588Z" fill="#11CD18"/>
</g>
<g clip-path="url(#clip1_64_1365)">
<path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z" fill="#11CD18"/>
</g>
<defs>
<clipPath id="clip0_64_1365">
<rect width="14.4" height="14.4" fill="white" transform="translate(4.79999 4.80005)"/>
</clipPath>
<clipPath id="clip1_64_1365">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

    
  )
}

export default GreenTickSvg