import React from 'react'

function EditIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0443 0.956628C18.4778 0.390959 17.7099 0.0732422 16.9093 0.0732422C16.1087 0.0732422 15.3408 0.390959 14.7743 0.956628L1.22097 14.51C0.832844 14.8959 0.525103 15.355 0.31555 15.8606C0.105996 16.3663 -0.00120958 16.9084 0.000134555 17.4558V19.1666C0.000134555 19.3876 0.0879319 19.5996 0.244212 19.7559C0.400493 19.9122 0.612454 20 0.833468 20H2.5443C3.0916 20.0015 3.63378 19.8945 4.13943 19.6851C4.64509 19.4756 5.10418 19.168 5.49013 18.78L19.0443 5.22579C19.6097 4.65929 19.9273 3.8916 19.9273 3.09121C19.9273 2.29082 19.6097 1.52313 19.0443 0.956628ZM4.3118 17.6016C3.8418 18.0685 3.20677 18.3314 2.5443 18.3333H1.6668V17.4558C1.66596 17.1274 1.73026 16.8021 1.856 16.4987C1.98173 16.1953 2.1664 15.9198 2.3993 15.6883L12.6851 5.40246L14.6018 7.31913L4.3118 17.6016ZM17.8651 4.04746L15.7768 6.13663L13.8601 4.22413L15.9493 2.13496C16.0752 2.00938 16.2245 1.90983 16.3888 1.84197C16.5532 1.77411 16.7292 1.73929 16.907 1.73948C17.0848 1.73967 17.2608 1.77488 17.425 1.8431C17.5892 1.91131 17.7383 2.01119 17.8639 2.13704C17.9895 2.26289 18.089 2.41225 18.1569 2.57657C18.2247 2.7409 18.2596 2.91698 18.2594 3.09477C18.2592 3.27255 18.224 3.44856 18.1557 3.61274C18.0875 3.77692 17.9877 3.92605 17.8618 4.05163L17.8651 4.04746Z" fill="#363C4C"/>
    </svg>
    
  )
}

export default EditIcon