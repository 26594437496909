import React from 'react'

function AddSvg() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" fill="url(#paint0_linear_45_1062)"/>
    <path d="M38.875 30.625H33.375V25.125C33.375 24.7603 33.2301 24.4106 32.9723 24.1527C32.7144 23.8949 32.3647 23.75 32 23.75C31.6353 23.75 31.2856 23.8949 31.0277 24.1527C30.7699 24.4106 30.625 24.7603 30.625 25.125V30.625H25.125C24.7603 30.625 24.4106 30.7699 24.1527 31.0277C23.8949 31.2856 23.75 31.6353 23.75 32C23.75 32.3647 23.8949 32.7144 24.1527 32.9723C24.4106 33.2301 24.7603 33.375 25.125 33.375H30.625V38.875C30.625 39.2397 30.7699 39.5894 31.0277 39.8473C31.2856 40.1051 31.6353 40.25 32 40.25C32.3647 40.25 32.7144 40.1051 32.9723 39.8473C33.2301 39.5894 33.375 39.2397 33.375 38.875V33.375H38.875C39.2397 33.375 39.5894 33.2301 39.8473 32.9723C40.1051 32.7144 40.25 32.3647 40.25 32C40.25 31.6353 40.1051 31.2856 39.8473 31.0277C39.5894 30.7699 39.2397 30.625 38.875 30.625Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_45_1062" x1="-5.27059" y1="1.54379e-05" x2="76.1406" y2="5.35614" gradientUnits="userSpaceOnUse">
    <stop stop-color="#996AD0"/>
    <stop offset="1" stop-color="#5C71DD"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default AddSvg