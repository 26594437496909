import React from 'react'

function AddStaffSvg() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_100_2067)">
<path d="M19.1667 9.16589H17.5V7.49921C17.5 7.03898 17.1269 6.66589 16.6667 6.66589C16.2065 6.66589 15.8334 7.03898 15.8334 7.49921V9.16589H14.1667C13.7065 9.16589 13.3334 9.53897 13.3334 9.99921C13.3334 10.4594 13.7065 10.8325 14.1667 10.8325H15.8334V12.4992C15.8334 12.9594 16.2065 13.3325 16.6667 13.3325C17.1269 13.3325 17.5 12.9594 17.5 12.4992V10.8325H19.1667C19.6269 10.8325 20 10.4594 20 9.99921C20 9.53897 19.6269 9.16589 19.1667 9.16589Z" fill="#374957"/>
<path d="M7.5 10C10.2614 10 12.5 7.76142 12.5 5C12.5 2.23858 10.2614 0 7.5 0C4.73858 0 2.5 2.23858 2.5 5C2.5 7.76142 4.73858 10 7.5 10Z" fill="#374957"/>
<path d="M7.5 11.6659C3.35977 11.6705 0.00460937 15.0257 0 19.1659C0 19.6261 0.373086 19.9992 0.83332 19.9992H14.1666C14.6269 19.9992 15 19.6261 15 19.1659C14.9954 15.0257 11.6402 11.6705 7.5 11.6659Z" fill="#374957"/>
</g>
<defs>
<clipPath id="clip0_100_2067">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default AddStaffSvg