import React from 'react'

function WhatsAppSvg() {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.67157 30.8448L9.34359 24.7732C8.30987 22.991 7.7676 20.9728 7.77325 18.9096C7.77325 12.4444 13.0604 7.18799 19.5508 7.18799C22.7028 7.18799 25.6627 8.40793 27.8826 10.6229C30.1082 12.838 31.334 15.7838 31.3284 18.9152C31.3284 25.3803 26.0412 30.6368 19.5451 30.6368H19.5395C17.5681 30.6368 15.6306 30.1421 13.9077 29.2088L7.67157 30.8448ZM14.2071 27.0894L14.563 27.303C16.0655 28.1913 17.7884 28.6579 19.5451 28.6635H19.5508C24.9453 28.6635 29.34 24.2953 29.34 18.9208C29.34 16.3179 28.3232 13.8724 26.4761 12.0284C24.629 10.1844 22.1662 9.17251 19.5508 9.17251C14.1563 9.16688 9.76159 13.5351 9.76159 18.9096C9.76159 20.7479 10.2756 22.5413 11.2585 24.0929L11.4901 24.464L10.5016 28.0563L14.2071 27.0894Z" fill="#C8C8C8"/>
    <path d="M8.08392 30.4344L9.69945 24.5708C8.69963 22.8561 8.1743 20.9053 8.1743 18.9152C8.17995 12.6749 13.2807 7.59839 19.5508 7.59839C22.5955 7.59839 25.448 8.77898 27.5946 10.9153C29.7411 13.0516 30.9216 15.8963 30.9216 18.9208C30.9216 25.1611 25.8152 30.2376 19.5508 30.2376H19.5451C17.6415 30.2376 15.7718 29.7598 14.1111 28.8603L8.08392 30.4344Z" fill="#F8F3FF"/>
    <path d="M7.67157 30.8448L9.34359 24.7732C8.30987 22.991 7.7676 20.9728 7.77325 18.9096C7.77325 12.4444 13.0604 7.18799 19.5508 7.18799C22.7028 7.18799 25.6627 8.40793 27.8826 10.6229C30.1082 12.838 31.334 15.7838 31.3284 18.9152C31.3284 25.3803 26.0412 30.6368 19.5451 30.6368H19.5395C17.5681 30.6368 15.6306 30.1421 13.9077 29.2088L7.67157 30.8448ZM14.2071 27.0894L14.563 27.303C16.0655 28.1913 17.7884 28.6579 19.5451 28.6635H19.5508C24.9453 28.6635 29.34 24.2953 29.34 18.9208C29.34 16.3179 28.3232 13.8724 26.4761 12.0284C24.629 10.1844 22.1662 9.17251 19.5508 9.17251C14.1563 9.16688 9.76159 13.5351 9.76159 18.9096C9.76159 20.7479 10.2756 22.5413 11.2585 24.0929L11.4901 24.464L10.5016 28.0563L14.2071 27.0894Z" fill="#C8C8C8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6077 14.0072C16.3874 13.5181 16.1558 13.5068 15.9468 13.5012C15.7774 13.4956 15.5797 13.4956 15.382 13.4956C15.1843 13.4956 14.8679 13.5687 14.5968 13.861C14.3257 14.1534 13.5687 14.8617 13.5687 16.3065C13.5687 17.7457 14.625 19.14 14.7719 19.3367C14.9188 19.5335 16.8111 22.5862 19.7992 23.7611C22.2847 24.7393 22.7931 24.5426 23.3297 24.492C23.8663 24.4414 25.0695 23.7836 25.318 23.0978C25.5609 22.4119 25.5609 21.8272 25.4875 21.7035C25.4141 21.5798 25.2164 21.5068 24.9226 21.3606C24.6289 21.2144 23.1828 20.5061 22.9117 20.4049C22.6405 20.3093 22.4428 20.2587 22.2508 20.551C22.0531 20.8434 21.4882 21.5011 21.3188 21.6979C21.1493 21.8947 20.9742 21.9172 20.6804 21.771C20.3867 21.6248 19.4377 21.3156 18.3136 20.3149C17.4381 19.5391 16.845 18.5778 16.6755 18.2854C16.5061 17.9931 16.6586 17.8357 16.8054 17.6895C16.9354 17.5602 17.0992 17.3466 17.246 17.1779C17.3929 17.0093 17.4437 16.8856 17.5398 16.6888C17.6358 16.4921 17.5906 16.3234 17.5172 16.1772C17.4437 16.0367 16.8676 14.5862 16.6077 14.0072Z" fill="#C8C8C8"/>
    </svg>
    
  )
}

export default WhatsAppSvg