import React from 'react'

function SendFileSvg() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="18" fill="#F2F2F2"/>
<g clip-path="url(#clip0_45_694)">
<path d="M23.4583 9H13.5417C12.6027 9.00112 11.7025 9.37462 11.0386 10.0386C10.3746 10.7025 10.0011 11.6027 10 12.5417L10 22.4583C10.0011 23.3973 10.3746 24.2975 11.0386 24.9614C11.7025 25.6254 12.6027 25.9989 13.5417 26H23.4583C24.3973 25.9989 25.2975 25.6254 25.9614 24.9614C26.6254 24.2975 26.9989 23.3973 27 22.4583V12.5417C26.9989 11.6027 26.6254 10.7025 25.9614 10.0386C25.2975 9.37462 24.3973 9.00112 23.4583 9V9ZM13.5417 10.4167H23.4583C24.0219 10.4167 24.5624 10.6406 24.9609 11.0391C25.3595 11.4376 25.5833 11.9781 25.5833 12.5417V22.4583C25.5821 22.7739 25.5095 23.0851 25.3708 23.3685L18.8804 16.8781C18.5515 16.5491 18.161 16.2881 17.7313 16.1101C17.3015 15.9321 16.8409 15.8404 16.3757 15.8404C15.9105 15.8404 15.4499 15.9321 15.0202 16.1101C14.5904 16.2881 14.1999 16.5491 13.871 16.8781L11.4167 19.3317V12.5417C11.4167 11.9781 11.6406 11.4376 12.0391 11.0391C12.4376 10.6406 12.9781 10.4167 13.5417 10.4167ZM13.5417 24.5833C12.9781 24.5833 12.4376 24.3595 12.0391 23.9609C11.6406 23.5624 11.4167 23.0219 11.4167 22.4583V21.3349L14.8719 17.8797C15.0693 17.6822 15.3036 17.5255 15.5615 17.4187C15.8194 17.3118 16.0958 17.2568 16.375 17.2568C16.6542 17.2568 16.9306 17.3118 17.1885 17.4187C17.4464 17.5255 17.6807 17.6822 17.8781 17.8797L24.3685 24.3708C24.0851 24.5095 23.7739 24.5821 23.4583 24.5833H13.5417Z" fill="#374957"/>
<path d="M21.3334 16.4373C21.8237 16.4373 22.3031 16.2919 22.7108 16.0195C23.1185 15.7471 23.4362 15.3599 23.6239 14.9069C23.8115 14.4539 23.8606 13.9554 23.7649 13.4745C23.6693 12.9936 23.4332 12.5519 23.0865 12.2051C22.7397 11.8584 22.298 11.6223 21.8171 11.5266C21.3362 11.431 20.8377 11.4801 20.3847 11.6677C19.9317 11.8554 19.5445 12.1731 19.2721 12.5808C18.9996 12.9885 18.8542 13.4678 18.8542 13.9582C18.8542 14.6157 19.1154 15.2463 19.5804 15.7112C20.0453 16.1761 20.6759 16.4373 21.3334 16.4373ZM21.3334 12.8957C21.5436 12.8957 21.749 12.958 21.9237 13.0747C22.0984 13.1915 22.2346 13.3574 22.315 13.5516C22.3955 13.7457 22.4165 13.9594 22.3755 14.1655C22.3345 14.3716 22.2333 14.5609 22.0847 14.7095C21.9361 14.8581 21.7468 14.9593 21.5407 15.0003C21.3346 15.0413 21.121 15.0202 20.9268 14.9398C20.7327 14.8594 20.5667 14.7232 20.45 14.5485C20.3332 14.3737 20.2709 14.1683 20.2709 13.9582C20.2709 13.6764 20.3829 13.4061 20.5821 13.2069C20.7814 13.0076 21.0516 12.8957 21.3334 12.8957Z" fill="#374957"/>
</g>
<defs>
<clipPath id="clip0_45_694">
<rect width="17" height="17" fill="white" transform="translate(10 9)"/>
</clipPath>
</defs>
</svg>

  )
}

export default SendFileSvg